import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import FeaturesTable from "../../additional_components/marketResearch/appTracking/FeaturesTable"

export default function ComparisonTabs({ appListData }) {
  const [activeTab1, setactiveTab1] = useState("1")
  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  return (
    <>
      <Nav pills>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "1",
            })}
            onClick={() => {
              toggle1("1")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">Features</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "2",
            })}
            onClick={() => {
              toggle1("2")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Performance</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab1} className="p-3 text-muted">
        <TabPane tabId="1">
          {appListData.length != 0 ? (
            <FeaturesTable appListData={appListData} />
          ) : (
            ""
          )}
          {/* <FeaturesTable appListData={appListData} /> */}
        </TabPane>
        <TabPane tabId="2">
          <p className="mb-0">
            Food truck fixie locavore, accusamus mcsweeney&apos;s marfa nulla
            single-origin coffee squid. Exercitation +1 labore velit, blog
            sartorial PBR leggings next level wes anderson artisan four loko
            farm-to-table craft beer twee. Qui photo booth letterpress, commodo
            enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum
            PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus
            mollit. Keytar helvetica VHS salvia yr, vero magna velit sapiente
            labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit.
          </p>
        </TabPane>
      </TabContent>
    </>
  )
}
