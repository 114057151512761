import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import FeaturesTable from "../../marketResearch/appTracking/FeaturesTable"
import RankedDashedLine from "../charts-graphs/RankDashedLine"
import ScreenShotCarousel from "./ScreenShotCarousel"
import Reviews from "./Reviews"
import { api, token } from "../../../utils/variables"
import axios from "axios"
export default function InfoTabs({ appListData }) {
  const [activeTab1, setactiveTab1] = useState("1")

  const formatDateToYYYYMMDD = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0") // Add 1 to month (0-indexed) and pad with leading zero
    const day = String(date.getDate()).padStart(2, "0") // Pad day with leading zero if necessary
    return `${year}-${month}-${day}`
  }

  const onemonthbackDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1 - 1).padStart(2, "0") // Add 1 to month (0-indexed) and pad with leading zero
    const day = String(date.getDate()).padStart(2, "0") // Pad day with leading zero if necessary
    return `${year}-${month}-${day}`
  }

  const addApp = async value => {
    const gameid = { item_id: { value } }
    try {
      const res = await axios.post(`${api}/tracker/tracking/app/`, gameid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      console.log(res)
    } catch (error) {
      console.error("Error sending data:", error)
    }
  }

  const [startDate, setStartDate] = useState(onemonthbackDate(new Date()))
  const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(new Date()))
  const [formstartDate, setformStartDate] = useState()
  const comments = [
    {
      id: 1,
      rating: "4",
      user: "Samuel",
      description:
        "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet",
    },
    {
      id: 2,
      rating: "5",
      user: "Jackson",
      description:
        "It will be as simple as in fact, it will be Occidental. It will seem like simplified",
    },
  ]

  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  return (
    <>
      <Nav pills className="nav-justified bg-light">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "1",
            })}
            onClick={() => {
              toggle1("1")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">About</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "2",
            })}
            onClick={() => {
              toggle1("2")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Category Ranking</span>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "3",
            })}
            onClick={() => {
              toggle1("3")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Ratings and Reviews</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "4",
            })}
            onClick={() => {
              toggle1("4")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Tracking</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab1} className="p-3 text-muted">
        <TabPane tabId="1">
          <div className="d-flex flex-wrap justify-content-between gap-1 mt-3">
            <Card
              outline
              color="white"
              className="border border-white"
              style={{ width: "550px" }}
            >
              <CardHeader className="bg-transparent border-white text-white">
                Release Detail
              </CardHeader>
              <CardBody className="p-4">
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <tbody>
                      <tr>
                        <td>Current Version :</td>
                        <td className="text-end">
                          {appListData?.version || "NA"}
                        </td>
                      </tr>
                      <tr>
                        <td>Age Rating : </td>
                        <td className="text-end">
                          {appListData?.age_rating || "NA"}
                        </td>
                      </tr>
                      <tr>
                        <td>Publisher:</td>
                        <td className="text-end">
                          {appListData?.publisher || "NA"}
                        </td>
                      </tr>
                      <tr>
                        <td>Release Date : </td>
                        <td className="text-end">
                          {appListData?.created_at || "NA"}
                        </td>
                      </tr>
                      <tr className="bg-light">
                        <th>File Size :</th>
                        <td className="text-end">
                          <span className="fw-bold">
                            {appListData?.size / 1000000 -
                              (appListData?.size % 1000000) / 1000000}{" "}
                            MB
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>

            <Card
              outline
              color="white"
              className="border border-white"
              style={{ width: "550px" }}
            >
              <CardHeader className="bg-transparent border-white text-white">
                Description
              </CardHeader>
              <CardBody>
                <CardText>{appListData?.description || "NA"}</CardText>
              </CardBody>
            </Card>
          </div>
          <Card style={{ width: "100%" }}>
            <CardHeader>Screenshots</CardHeader>
            <CardBody>
              <ScreenShotCarousel appData={appListData}></ScreenShotCarousel>
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="2">
          <div className="mt-3">
            <p>
              <b>Rank Development</b>
            </p>
            <div className="mt-5 d-flex flex-wrap justify-content-between">
              <div className="d-flex flex-wrap gap-4">
                <label htmlFor="example-date-input" className="col-form-label">
                  From
                </label>
                <div className="">
                  <input
                    className="form-control"
                    type="date"
                    defaultValue={startDate}
                    onChange={e => setStartDate(e.target.value)}
                    id="example-date-input"
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap gap-4">
                <label htmlFor="example-date-input" className="col-form-label">
                  To
                </label>
                <div className="">
                  <input
                    className="form-control"
                    type="date"
                    defaultValue={endDate}
                    onChange={e => setEndDate(e.target.value)}
                    id="example-date-input"
                  />
                </div>
              </div>
            </div>
            <hr></hr>
            <div>
              <RankedDashedLine startDate={startDate} endDate={endDate} />
            </div>
          </div>
        </TabPane>
        <TabPane tabId="3">
          <Reviews comments={comments} appdata={appListData}></Reviews>
        </TabPane>
        <TabPane tabId="4">
          <p>Tracker</p>
          <Button
            onClick={() => {
              addApp(appListData.item_id)
            }}
          >
            Track App
          </Button>
        </TabPane>
      </TabContent>
    </>
  )
}
