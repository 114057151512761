import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import StepperProgress from "../../additional_components/common/Stepper"
import NavTabs from "../../additional_components/NavTabs"
import ProgressCard from "../../additional_components/common/ProgressCard"
//import PerformanceTable from "../additional_components/PerformanceTable"
import PerformanceDataTable from "../../additional_components/marketResearch/PerformanceDataTable"
import axios from "axios"
import { api } from "../../utils/variables"
import CategoryDataTable from "../../additional_components/marketResearch/CategoryDataTable"

export default function CategoryAnalysis() {
  const [searchValue, setsearchValue] = useState("")
  const [inputValue, setInputValue] = useState("")
  const [modal_toggle, setmodal_toggle] = useState(false)
  const [second_modal_toggle, setsecond_modal_toggle] = useState(false)
  const [categoryApps, setcategoryApps] = useState([])
  const [filteredApps, setCatFilteredApps] = useState([])
  const [selectedPlatform, changePlatform] = useState("sidequest")
  const [categoryData, setcategoryData] = useState([])
  const [dataAction, setDataAction] = useState("loading")
  const keywordarray = [
    "Action",
    "VR Shooter",
    "adventure",
    "Reality",
    "app",
    "racing",
  ]
  const [predefCategory, setpredef] = useState([])
  const storedAppsData = sessionStorage.getItem("categoryApps")
  const storedcategoryData = sessionStorage.getItem("categoryData")
  useEffect(() => {
    fetchPredefinedCategory()
    console.log(storedAppsData)
    if (storedAppsData) {
      setcategoryApps(JSON.parse(storedAppsData))
      setCatFilteredApps(JSON.parse(storedAppsData))
      //setcategoryData(JSON.parse(storedcategoryData))
    }
  }, [])

  function toggle_modal() {
    setmodal_toggle(!modal_toggle)
    setsecond_modal_toggle(false)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleSearch() // Call the function when Enter is pressed
    }
  }

  const predefSearch = value => {
    setInputValue(value)
    toggle_modal()
    fetchSearchResult(value)
  }

  const fetchPredefinedCategory = () => {
    //https://api.triviumpro.in/predefined/category
    axios.get(`${api}/tracker/predefined/category`, {}).then(res => {
      console.log(res.data.keywords)
      setpredef(res.data.keywords)
    })
  }
  const fetchSearchResult = async value => {
    try {
      // Initial API call to get the action and details
      let res = await axios.get(`${api}/tracker/category/${value}`, {})
      setDataAction(res.data.action)
      //toggle_modal()
      // Polling until the action becomes "display"
      while (res.data.action.toLowerCase() !== "display") {
        console.log("Waiting for action to become 'display'...")

        // Wait for 2 seconds before fetching again
        await new Promise(resolve => setTimeout(resolve, 2000))

        // Fetch again to check the action
        res = await axios.get(`${api}/tracker/category/${value}`, {})
        setDataAction(res.data.action)
      }

      // Once action is "display", fetch the games
      console.log("Action is 'display', fetching games...")
      setcategoryData(res.data.games)
      setcategoryApps(res.data.games.games)
      setCatFilteredApps(res.data.games.games)
      console.log(res.data.games.games)
      sessionStorage.setItem(
        "categoryApps",
        JSON.stringify(res.data.games.games)
      )
      sessionStorage.setItem(
        "filteredApps",
        JSON.stringify(res.data.games.games)
      )
      // sessionStorage.setItem("categoryData", JSON.stringify(res.data.games))

      // Close modal since we are done
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  const handleSearch = async () => {
    toggle_modal()
    await fetchSearchResult(inputValue)
    await setsearchValue(inputValue)
  }

  // Callback function to handle the platform and select data from the navtabs
  const handlePlatformChange = platform => {
    changePlatform(platform) // Update the platform
    if (platform != "all") {
      const platformFilteredGames = categoryApps.filter(
        game => game.platform === platform
      )
      setCatFilteredApps(platformFilteredGames)
    }
    //return all games
    else {
      const platformFilteredGames = categoryApps.filter(game => game)
      setCatFilteredApps(platformFilteredGames)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content m-3">
        <Breadcrumb
          title="Category Analysis"
          breadcrumbItem="Category Analysis"
        />
        <Row>
          <div>
            <p>See search results from any stores in Realtime</p>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="d-flex flex-wrap gap-3">
                <div className="search-box chat-search-box position-relative col-sm-auto">
                  <Input
                    type="text"
                    className="form-control bg-light border-light rounded"
                    placeholder="Search..."
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)} // Update input value
                    onKeyDown={handleKeyDown}
                  />
                  <i className="uil uil-search search-icon"></i>
                </div>
                <Button
                  color="primary"
                  className=" col-sm-auto waves-effect waves-light"
                  //   onClick={() => {
                  //     toggle_modal()
                  //   }}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </div>
              <div className="d-flex flex-wrap gap-3">
                <label className="md-3 col-form-label">Select Platform</label>
                <div className="md-3 col-sm-auto ">
                  <select className="form-control">
                    <option>Select</option>
                    <option>Platform1</option>
                    <option>Platform2</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Row>
        <hr className="mt-4"></hr>
        <Row className="mt-4">
          <NavTabs onPlatformChange={handlePlatformChange} />
        </Row>

        <Row className="mt-4">
          <h6>Keyword Intelligence: {searchValue}</h6>
          <Col xs={12} className="mt-2">
            <div className="d-flex flex-wrap justify-content-center align-item-center gap-2">
              <div style={{ width: "24%", minWidth: "150px" }}>
                <ProgressCard
                  heading="Total Downloads"
                  count={categoryData?.total_downloads}
                  percentage="100"
                />
              </div>
              <div style={{ width: "24%", minWidth: "150px" }}>
                <ProgressCard
                  heading="Total Reviews"
                  count={categoryData?.total_reviews}
                  percentage="100"
                />
              </div>
              <div style={{ width: "24%", minWidth: "150px" }}>
                <ProgressCard
                  heading="Total Money"
                  count={categoryData?.total_money}
                  percentage="100"
                />
              </div>
              <div style={{ width: "24%", minWidth: "150px" }}>
                <ProgressCard
                  heading="Total Games"
                  count={categoryData?.total_games}
                  percentage="100"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <p>Words Commonly used</p>
          <div className="d-flex flex-wrap gap-2">
            {predefCategory.map((value, index) => {
              return (
                <Button
                  key={index}
                  color="dark"
                  className="btnparent btn-rounded"
                  onClick={() => predefSearch(value)}
                >
                  <div className="keywordbtn">{value}</div>
                </Button>
              )
            })}
          </div>
          <Row className="mt-2">
            <hr></hr>
          </Row>

          <p className="mt-4">
            Showing Top 50 results for categroy: {inputValue}
          </p>

          <div className="mt-4">
            {/* <PerformanceTable data={[1, 2]} /> */}
            {categoryApps.length > 0 ? (
              <CategoryDataTable
                inputdata={inputValue}
                gamesData={filteredApps}
              />
            ) : (
              <></>
            )}
          </div>
        </Row>
      </div>

      {/* Loading Modal */}
      <Modal
        isOpen={modal_toggle}
        toggle={() => {
          toggle_modal()
        }}
        centered
        size="lg"
        style={{ maxHeight: "100vh" }}
      >
        <ModalBody
          style={{
            maxHeight: "100vh",
            minHeight: "40vh",
            padding: "20px",
            // overflowY: "auto",
          }}
          className="position-relative"
        >
          <div className="d-flex justify-content-center">
            <Spinner size="" className="mt-4" color="primary" />
          </div>
          <div className="">
            <p className="mt-4 text-center">
              <b>Wait While we are fetching your data</b>
            </p>
            <p className="mt-1 text-center">
              Funfact: loremipsum is the fun fact Funfact: loremipsum is the fun
              factFunfact: loremipsum is the fun factFunfactFunfact: loremipsum
              is the fun fact Funfact:
            </p>
          </div>
          <div className="mt-5 pb-5" style={{ maxWidth: "100%" }}>
            <StepperProgress
              closeModal={() => {
                toggle_modal()
              }}
              isFetched={dataAction}
            />
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
