import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { FaCirclePlus } from "react-icons/fa6"
import AppListModal from "../../additional_components/common/Modals/AppListModal"
import { Link } from "react-router-dom"
import classnames from "classnames"
import ComparisonTabs from "./comparisonTabs"
import { api, token } from "../../utils/variables"
import axios from "axios"

export default function AppTracking() {
  const [activeTab1, setactiveTab1] = useState("5")

  const [modal_toggle, setmodal_toggle] = useState(false)
  const [comparisonList, setComparisonList] = useState([])
  const [comparisonBetween, setComparisonBetween] = useState([])
  const applist = ["app 1", "app 2", "app 3"]
  const applistdata = [
    {
      name: "MiniGolf 1",
      category: "Simulator",
      downloads: "1234",
      review: "3.4",
      revenue: "10k",
      sentimentanalysis: "",
    },
    {
      name: "Reliance Air",
      category: "Simulator",
      downloads: "2341",
      review: "4.0",
      revenue: "50k",
      sentimentanalysis: "",
    },
    {
      name: "VR Shoot",
      category: "Shooter",
      downloads: "1452",
      review: "3.8",
      revenue: "36k",
      sentimentanalysis: "",
    },
  ]

  useEffect(() => {
    fetchTrackedAppList()
    console.log(comparisonBetween)
  }, [])

  const fetchTrackedAppList = () => {
    axios
      .get(`${api}/tracker/tracking/app`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log(res.data.games)
        setComparisonList(res.data.games)
      })
  }

  function toggle_modal() {
    setmodal_toggle(!modal_toggle)
  }
  const handleselectedapp = async app => {
    // Add the selected app to the array
    const appExists = comparisonList.some(
      item => item.game_details.item_id === app.item_id
    )
    console.log("existance checked")
    console.log(app)
    if (!appExists) {
      // Add the selected app to the array
      const gameid = { item_id: app.item_id }
      try {
        const res = await axios.post(`${api}/tracker/tracking/app/`, gameid, {
          headers: { Authorization: `Bearer ${token}` },
        })
        console.log(res)
      } catch (error) {
        console.error("Error sending data:", error)
      }

      // setComparisonList(prevApps => [...prevApps, app])
      console.log(comparisonList)
    } else {
      console.log("App is already in the comparison list.")
    }
    fetchTrackedAppList()
    toggle_modal()
  }

  async function removeApp(val) {
    const gameid = { item_id: val }
    try {
      const res = await axios.delete(`${api}/tracker/tracking/app.remove`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { item_id: val }, // Adjust this key if the payload requires a different name
      })
      console.log(res)
      fetchTrackedAppList()
    } catch (error) {
      console.error("Error sending data:", error)
    }
  }
  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content m-3">
        <Breadcrumb title="App Tracking" breadcrumbItem="App Tracking" />
        <Row className="m-3">
          <Card className="">
            <CardBody>
              <Row className="mt-3">
                <div className="d-flex justify-content-between">
                  <p>Compare 2-6 Apps</p>
                  <Button onClick={() => setComparisonList([])}>
                    Clear Selection
                  </Button>
                </div>
              </Row>
              <Row>
                <div className="d-flex flex-wrap gap-2">
                  {comparisonList.map((app, index) => {
                    return (
                      <div className="col-md-2">
                        {/* <Link to="/gamedashboard" className=""> */}
                        <Card
                          className="app-card-container border border-1 rounded"
                          onClick={() => removeApp(app.game_details.item_id)} // Replace with your click handler
                          style={{
                            border: "none",
                            cursor: "pointer", // Makes the card look clickable
                            transition: "background-color 0.3s ease", // Smooth transition for hover effect
                            minHeight: 80,
                          }}
                        >
                          <CardBody className="mx-auto">
                            <div
                              className="d-flex gap-2 my-auto"
                              style={{ minHeight: 80, minWidth: 90 }}
                            >
                              <p className="my-auto">
                                <b>{app.game_details.name}</b>
                              </p>
                            </div>
                          </CardBody>
                        </Card>
                        {/* </Link> */}
                      </div>
                    )
                  })}

                  <div className="col-md-2">
                    {comparisonList.length < 6 ? (
                      <Card
                        className="app-card-container  border border-1 rounded"
                        onClick={toggle_modal} // Replace with your click handler
                        style={{
                          border: "none",
                          cursor: "pointer", // Makes the card look clickable
                          transition: "background-color 0.3s ease", // Smooth transition for hover effect
                          minHeight: 80,
                        }}
                      >
                        <CardBody className="mx-auto">
                          <div
                            className="d-flex gap-2 my-auto"
                            style={{ minHeight: 80 }}
                          >
                            <FaCirclePlus size={20} className="my-auto" />
                            <p className="my-auto">
                              <b>Add game</b>
                            </p>
                          </div>
                        </CardBody>
                      </Card>
                    ) : (
                      <></>
                    )}
                    {/* <Card
                      className="app-card-container  border border-1 rounded"
                      onClick={toggle_modal} // Replace with your click handler
                      style={{
                        border: "none",
                        cursor: "pointer", // Makes the card look clickable
                        transition: "background-color 0.3s ease", // Smooth transition for hover effect
                        minHeight: 80,
                      }}
                    >
                      <CardBody className="mx-auto">
                        <div
                          className="d-flex gap-2 my-auto"
                          style={{ minHeight: 80 }}
                        >
                          <FaCirclePlus size={20} className="my-auto" />
                          <p className="my-auto">
                            <b>Add game</b>
                          </p>
                        </div>
                      </CardBody>
                    </Card> */}
                  </div>
                </div>
              </Row>

              <Row style={{ paddingBottom: 20 }}>
                <div className="d-flex justify-content-center">
                  <Button
                    className="col-md-3"
                    color={comparisonList.length < 1 ? "secondary" : "primary"}
                    onClick={() => setComparisonBetween(comparisonList)}
                    style={{
                      cursor:
                        comparisonList.length < 1 ? "not-allowed" : "pointer", // Change cursor based on selection
                      // Different color for already selected apps
                    }}
                  >
                    Compare
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Row>

        {/* Comparison tables and charts */}
        <Row className="m-3">
          <ComparisonTabs appListData={comparisonBetween}></ComparisonTabs>
        </Row>
      </div>
      <AppListModal
        modaltoggle={modal_toggle}
        togglemodal={toggle_modal}
        applistData={applistdata}
        onAppSelect={handleselectedapp}
        alreadySelectedApps={comparisonList}
      ></AppListModal>
    </React.Fragment>
  )
}
