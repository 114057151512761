import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Col,
  Row,
  Button,
} from "reactstrap"

export default function SmallAppCard({ key, gamedata }) {
  return (
    <Link
      to="/gamedashboard"
      className=""
      state={{ app_id: key, data: gamedata }}
    >
      <Card
        className="app-card-container m-2"
        onClick={() => console.log("Card clicked!")} // Replace with your click handler
        style={{
          border: "none",
          cursor: "pointer", // Makes the card look clickable
          transition: "background-color 0.3s ease", // Smooth transition for hover effect
        }}
      >
        <Row className="g-0 align-items-center">
          <CardBody>
            <div className="d-flex flex-wrap gap-3">
              <CardImg
                className="img-fluid"
                src={gamedata?.image_url || "https://via.placeholder.com/50"}
                alt="image cap"
                style={{ maxWidth: 40 }}
              />
              <div className="align-center">
                <span class="align-middle">{gamedata.name}</span>
              </div>
            </div>
          </CardBody>
        </Row>
      </Card>
    </Link>
  )
}
