import { useEffect } from "react"
import { Table } from "reactstrap"

export default function FeaturesTable({ appListData }) {
  const appData = appListData.game_details
  useEffect(() => {
    console.log(appListData)
    console.log(appData)
  }, [])

  return (
    <div className="table-responsive">
      <Table className="table table-dark mb-0">
        <thead>
          <tr>
            <th className="col-md-4" style={{ opacity: "70%" }}>
              Feature
            </th>
            {appListData.map((app, index) => {
              return <th className="text-center">{app.game_details.name}</th>
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Category
            </td>
            {appListData.map((app, index) => {
              return (
                <td className="text-center">{app.game_details.category}</td>
              )
            })}
          </tr>
          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Age Rating
            </td>
            {appListData.map((app, index) => {
              return (
                <td className="text-center">{app.game_details.age_rating}</td>
              )
            })}
          </tr>
          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Game Modes
            </td>
            {appListData.map((app, index) => {
              return (
                <td className="text-center">{app.game_details.game_modes}</td>
              )
            })}
          </tr>
          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Genres
            </td>
            {appListData.map((app, index) => {
              return (
                <td className="text-center">
                  {app.game_details.genres.join(", ")}
                </td>
              )
            })}
          </tr>
          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Downloads
            </td>
            {appListData.map((app, index) => {
              return (
                <td className="text-center">{app.game_details.downloads}</td>
              )
            })}
          </tr>
          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Review
            </td>
            {appListData.map((app, index) => {
              return <td className="text-center">{app.game_details.review}</td>
            })}
          </tr>
          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Revenue
            </td>
            {appListData.map((app, index) => {
              return <td className="text-center">{app.game_details.revenue}</td>
            })}
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
