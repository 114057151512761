import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumb from "../components/Common/Breadcrumb"
import StepperProgress from "../additional_components/common/Stepper"
import NavTabs from "../additional_components/NavTabs"
import ProgressCard from "../additional_components/common/ProgressCard"
//import PerformanceTable from "../additional_components/PerformanceTable"
import PerformanceDataTable from "../additional_components/marketResearch/PerformanceDataTable"
import axios from "axios"
import { api } from "../utils/variables"
import AppCard from "../additional_components/marketResearch/AppCard"
import Line from "../additional_components/Line"
import LineChart from "../additional_components/common/charts-graphs/RankDashedLine"
import ELineChart from "../additional_components/common/charts-graphs/E-LineChart"
import DashedLine from "../additional_components/common/charts-graphs/RankDashedLine"
import RankedDashedLine from "../additional_components/common/charts-graphs/RankDashedLine"
import { useLocation } from "react-router-dom"
import InfoTabs from "../additional_components/common/dashboardComponent/InfoTabs"
import ScreenShotCarousel from "../additional_components/common/dashboardComponent/ScreenShotCarousel"
import metalogo from "../assets/images/meta-logo.png"
import sidelogo from "../assets/images/SideQuest-Mark-White.png"

export default function GameDashboard() {
  const [inputValue, setInputValue] = useState("")
  const [appdetail, setAppdetail] = useState([])
  const [modal_toggle, setmodal_toggle] = useState(false)
  const keywordarray = [
    "Action",
    "VR Shooter",
    "Gaming",
    "Reality",
    "app",
    "racing",
  ]
  const location = useLocation()
  const { app_id, data } = location.state || {}

  useEffect(() => {
    console.log(data.item_id)
    fetchAppDetails(data.item_id)
  }, [])

  const fetchAppDetails = app_id => {
    //https://api.triviumpro.in/predefined/keywords
    axios.get(`${api}/tracker/app/${app_id}`, {}).then(res => {
      setAppdetail(res.data.details)
      console.log(res.data.details)
    })
  }
  const ratingData = data?.rating
  const istotalratingAvailable = ratingData?.total_rating || false
  var overallRating = 0.0
  if (istotalratingAvailable) {
    overallRating = (
      (ratingData.rating_index["1"] +
        ratingData.rating_index["2"] * 2 +
        ratingData.rating_index["3"] * 3 +
        ratingData.rating_index["4"] * 4 +
        ratingData.rating_index["5"] * 5) /
      ratingData.total_rating
    ).toFixed(1)
  }
  return (
    <React.Fragment>
      <div className="page-content m-3">
        <Breadcrumb title="App Dashboard" breadcrumbItem="App Dashboard" />
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <Card>
                    <Row className="g-0 align-items-center">
                      <Col md={5}>
                        <CardImg
                          className="img-fluid p-2"
                          src={
                            data?.image_url || "https://via.placeholder.com/50"
                          }
                          alt="image cap"
                          style={{
                            minHeight: 200,
                            maxHeight: 200,
                            minWidth: 250,
                            objectfit: "cover",
                          }}
                        />
                      </Col>
                      <Col md={7}>
                        <CardBody>
                          {/* <CardTitle className="display-1">
                            
                          </CardTitle> */}
                          <b className="display-6 text-white">
                            {data?.name || "Demo1"}
                          </b>

                          <CardText>
                            {appdetail?.category || "category"}
                          </CardText>
                          <CardText>
                            <a href={appdetail?.website_url}>
                              {appdetail?.publisher || "publisher"}
                            </a>
                          </CardText>

                          <div className="d-flex justify-content-start gap-1">
                            {appdetail?.supported_platforms != null ? (
                              <img
                                src={metalogo}
                                className="rounded avatar-sm align-self-center"
                                alt=""
                                style={{
                                  minHeight: 30,
                                  maxHeight: 30,
                                  minWidth: 40,
                                  maxWidth: 40,
                                }}
                              />
                            ) : (
                              <img
                                src={sidelogo}
                                className="rounded avatar-sm align-self-center"
                                alt=""
                                style={{
                                  minHeight: 20,
                                  maxHeight: 20,
                                  minWidth: 20,
                                  maxWidth: 20,
                                }}
                              />
                            )}

                            <small className="text-muted align-self-center">
                              Last updated 3 mins ago
                            </small>
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              {/* <Col xs={12} className="mt-2">
                <div className="d-flex flex-wrap justify-content-center gap-1">
                  <Card
                    color="info"
                    className="border-info text-white-50"
                    style={{ width: "24%", minWidth: "140px" }}
                  >
                    <CardBody>
                      <CardTitle className="h4">Category</CardTitle>
                      <p className="card-title-desc">{appdetail.category}</p>
                    </CardBody>
                  </Card>
                  <Card style={{ width: "24%", minWidth: "140px" }}>
                    <CardBody>
                      <CardTitle className="h4">Price</CardTitle>
                      <p className="card-title-desc">
                        {appdetail.price?.amount +
                          " " +
                          appdetail.price?.currency}
                      </p>
                    </CardBody>
                  </Card>
                  <Card style={{ width: "24%", minWidth: "140px" }}>
                    <CardBody>
                      <CardTitle className="h4">Downloads</CardTitle>
                      <p className="card-title-desc">{appdetail.category}</p>
                    </CardBody>
                  </Card>
                  <Card style={{ width: "24%", minWidth: "140px" }}>
                    <CardBody>
                      <CardTitle className="h4">Ratings</CardTitle>
                      <p className="card-title-desc">
                        {appdetail.rating?.total_rating || "NA"}
                      </p>
                    </CardBody>
                  </Card>
                </div>
              </Col> */}
              <Row className="mt-3">
                <Col lg={3}>
                  <Card
                    color="info"
                    className="border-info text-white"
                    style={{ width: "100%", minWidth: "140px" }}
                  >
                    <CardBody>
                      <CardTitle className="h4">Category</CardTitle>
                      <p className="card-title-desc">
                        {appdetail?.category || "NA"}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card
                    color="info"
                    className="border-info text-white"
                    style={{ width: "100%", minWidth: "140px" }}
                  >
                    <CardBody>
                      <CardTitle className="h4">Price</CardTitle>
                      <p className="card-title-desc">
                        {appdetail.price?.amount +
                          " " +
                          appdetail.price?.currency}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card
                    color="info"
                    className="border-info text-white"
                    style={{ width: "100%", minWidth: "140px" }}
                  >
                    <CardBody>
                      <CardTitle className="h4">Downloads</CardTitle>
                      <p className="card-title-desc">
                        {appdetail?.downloads || "NA"}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card
                    color="info"
                    className="border-info text-white"
                    style={{ width: "100%", minWidth: "140px" }}
                  >
                    <CardBody>
                      <CardTitle className="h4">Ratings</CardTitle>
                      <p className="card-title-desc">{overallRating}</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* <div className="d-flex flex-wrap justify-content-between">
                <Col style={{ width: "50%" }}>
                  <div>
                    <hr></hr>
                    <Card className="p-40">
                      <CardBody>
                        <Row>
                          <Col style={{ paddingRight: 50 }}>
                            <div>
                              <p>Feature Indicator</p>
                              <div className="d-flex justify-content-between">
                                <p>POWERSCORE</p>
                                <p>93</p>
                              </div>
                              <hr></hr>
                              <Col>
                                <p>Gender</p>
                                <div className="d-flex justify-content-between">
                                  <p>Male</p>
                                  <p>93</p>
                                </div>
                                <div
                                  mt={1}
                                  className="d-flex justify-content-between"
                                >
                                  <p>Female</p>
                                  <p>93</p>
                                </div>
                              </Col>
                              <hr></hr>
                              <Col>
                                <p>Age</p>
                                <div className="d-flex justify-content-between">
                                  <p>Age 15-18</p>
                                  <p>93</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p>Age 18-25</p>
                                  <p>93</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p>Age 25+</p>
                                  <p>93</p>
                                </div>
                              </Col>
                            </div>
                          </Col>
                          <Col style={{ paddingRight: 50 }}>
                            <div>
                              <p>Sustained Rank</p>
                              <div className="d-flex justify-content-between">
                                <p>Grossing rank</p>
                                <p>13</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Downloading rank</p>
                                <p>28</p>
                              </div>
                              <hr></hr>
                              <Col>
                                <p>30 days performance</p>
                                <div className="d-flex justify-content-between">
                                  <p>Revenue</p>
                                  <p>93</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p>downloads</p>
                                  <p>93</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p>REV/DL</p>
                                  <p>93</p>
                                </div>
                              </Col>
                              <hr></hr>
                              <Col>
                                <p>360 days performance</p>
                                <div className="d-flex justify-content-between">
                                  <p>Revenue</p>
                                  <p>93</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p>downloads</p>
                                  <p>93</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p>REV/DL</p>
                                  <p>93</p>
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
                
              </div> */}
            </CardBody>
          </Card>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <InfoTabs appListData={appdetail}></InfoTabs>
            </CardBody>
          </Card>
        </Row>
      </div>

      {/* Loading Modal */}
    </React.Fragment>
  )
}
