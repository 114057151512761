import axios from "axios"
import { useState } from "react"
import { Button, Col, Input, Modal, ModalBody, Row } from "reactstrap"
import { api, token } from "../../../utils/variables"

export default function AppListModal({
  modaltoggle,
  togglemodal,
  applistData,
  onAppSelect,
  alreadySelectedApps,
}) {
  const [inputValue, setInputValue] = useState("")
  const [searchValue, setsearchValue] = useState("")
  const [categoryApps, setcategoryApps] = useState([])
  const [filteredApps, setCatFilteredApps] = useState([])
  const [dataAction, setDataAction] = useState("loading")

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleSearch() // Call the function when Enter is pressed
    }
  }

  const fetchSearchResult = async value => {
    try {
      // Initial API call to get the action and details
      let res = await axios.get(`${api}/tracker/keyword/${value}`, {})
      setDataAction(res.data.action)
      //toggle_modal()
      // Polling until the action becomes "display"
      while (res.data.action.toLowerCase() !== "display") {
        console.log("Waiting for action to become 'display'...")

        // Wait for 2 seconds before fetching again
        await new Promise(resolve => setTimeout(resolve, 2000))

        // Fetch again to check the action
        res = await axios.get(`${api}/tracker/keyword/${value}`, {})
        setDataAction(res.data.action)
      }

      // Once action is "display", fetch the games
      console.log("Action is 'display', fetching games...")
      setcategoryApps(res.data.games)
      setCatFilteredApps(res.data.games)
      sessionStorage.setItem("categoryApps", JSON.stringify(res.data.games))
      sessionStorage.setItem("filteredApps", JSON.stringify(res.data.games))

      // Close modal since we are done
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  const handleSearch = async () => {
    await fetchSearchResult(inputValue)
    await setsearchValue(inputValue)
  }
  return (
    <Modal
      isOpen={modaltoggle}
      toggle={() => {
        togglemodal()
      }}
      centered
      size="lg"
      style={{ maxHeight: "100vh" }}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Select an App
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            togglemodal()
          }}
          aria-label="Close"
        ></button>
      </div>
      <ModalBody
        style={{
          maxHeight: "100vh",
          minHeight: "40vh",
          padding: "20px",
        }}
      >
        <div>
          <Col>
            <div className="d-flex flex-wrap gap-3">
              <div className="search-box chat-search-box position-relative col-sm-auto">
                <Input
                  type="text"
                  className="form-control bg-light border-light rounded"
                  placeholder="Search..."
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)} // Update input value
                  onKeyDown={handleKeyDown}
                />
                <i className="uil uil-search search-icon"></i>
              </div>
              <Button
                color="primary"
                className=" col-sm-auto waves-effect waves-light"
                //   onClick={() => {
                //     toggle_modal()
                //   }}
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </Col>
          {/* return search list */}

          <Col className="mt-4">
            {filteredApps.slice(0, 10).map((val, index) => {
              // Check if the app is already selected
              const isAlreadySelected = alreadySelectedApps.some(
                selectedApp => selectedApp.game_details.name === val.name
              )
              return (
                <>
                  <Row>
                    <Col md={10}>
                      <p
                        key={index}
                        onClick={
                          !isAlreadySelected ? () => onAppSelect(val) : null
                        }
                        // style={{ cursor: "pointer" }}
                        style={{
                          cursor: isAlreadySelected ? "not-allowed" : "pointer", // Change cursor based on selection
                          color: isAlreadySelected ? "gray" : "", // Different color for already selected apps
                        }}
                      >
                        {val.name}
                      </p>
                    </Col>
                    {isAlreadySelected ? (
                      <Col md={2}>
                        <Button
                          color="danger"
                          className="btn-rounded waves-effect waves-light"
                        >
                          remove
                        </Button>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </>
              )
            })}
          </Col>
        </div>
      </ModalBody>
    </Modal>
  )
}
